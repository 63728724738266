<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="智慧医院运营管理系统（HRP）" describe="一体化智慧医院运营管理平台，集成财务、人力资源、绩效考核、物资、设备等管理模块，通过数据共享与流程优化，实现医院资源的高效调配与利用，帮助医院实现精准采购、成本控制、人员绩效评估等，有效提升医院运营效率、服务质量和管理决策的科学性，增强医院竞争力，推动医院规范化、精细化发展，助力医院在复杂多变的医疗市场中稳健前行。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/hrp1.png" class="img1" alt="">
      </div>
      <div class="product-name">产品分析</div>
      <div class="introduction-content">
        <img src="@/assets/product/hrp2.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1305px;
    margin: 0 auto;
    text-align: center;
    .img1 {
      width: 100%;
      height: 640px;
    }
    .img2 {
       width: 100%;
      height: 776px;
    }
  }
}
</style>
